// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import WeatherIntegration from "../../blocks/weatherintegration/src/WeatherIntegration";
import LocationFinder from "../../blocks/weatherintegration/src/LocationFinder";
import WeatherDetails from "../../blocks/weatherintegration/src/WeatherDetails";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Maps from "../../blocks/maps/src/Maps";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Cfapiviews6 from "../../blocks/cfapiviews6/src/Cfapiviews6";
import Cfemergencyreportbutton3 from "../../blocks/cfemergencyreportbutton3/src/Cfemergencyreportbutton3";
import Uploadmedia3 from "../../blocks/uploadmedia3/src/Uploadmedia3";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import AllEvents from "../../blocks/events/src/AllEvents";
import CreateEvent from "../../blocks/events/src/CreateEvent";
import EventDetail from "../../blocks/events/src/EventDetail";


const routeMap = {
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
LandingPage:{
 component:LandingPage,
path:"/LandingPage"},
WeatherIntegration:{
 component:WeatherIntegration,
path:"/WeatherIntegration"},
LocationFinder:{
 component:LocationFinder,
path:"/LocationFinder"},
WeatherDetails:{
 component:WeatherDetails,
path:"/WeatherDetails"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Maps:{
 component:Maps,
path:"/Maps"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Cfapiviews6:{
 component:Cfapiviews6,
path:"/Cfapiviews6"},
Cfemergencyreportbutton3:{
 component:Cfemergencyreportbutton3,
path:"/Cfemergencyreportbutton3"},
Uploadmedia3:{
 component:Uploadmedia3,
path:"/Uploadmedia3"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
AllEvents:{
 component:AllEvents,
path:"/AllEvents"},
CreateEvent:{
 component:CreateEvent,
path:"/CreateEvent"},
EventDetail:{
 component:EventDetail,
path:"/EventDetail"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;